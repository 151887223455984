<template>
  <div class="container pt-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
      <router-link to="/" class="imgWelCont">
        <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
      </router-link>
      <div class="txtWelCont">
        <p>Arquivos e Treinamentos</p>
      </div>
    </div>
    <div class="header" v-if="this.sizeWindow > 767">
      <h1>
        <router-link class="routerHome" :to="{ name: 'arquivos' }">
          Home
        </router-link>
        <strong>/ Arquivos e Treinamentos</strong>
      </h1>
    </div>
    <div class="main">
      <div class="containerMain" v-if="$route.name == 'arquivos'">
        <h1 v-if="this.sizeWindow > 767">Arquivos e Treinamentos</h1>
        <button
          v-if="profile === 1"
          class="btn_default"
          id="btn_upload"
          @click.prevent="openModal('externalUpload')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-upload"
            viewBox="0 0 16 16"
          >
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
            />
            <path
              d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"
            />
          </svg>
          Upload Arquivo
        </button>
        <div v-if="profile === 1">
          <!-- <button class="btn_default mr-2">Upload</button> -->
          <button class="btn_default" @click.prevent="showModal('add')">
            + Adicionar Categoria
          </button>
        </div>
      </div>

      <div v-if="load">
        <div class="d-flex align-items-center justify-content-center mt-4">
          <half-circle-spinner
            :animation-duration="1000"
            :size="80"
            color="#fff"
          />
        </div>
      </div>
      <div v-else>
        <h1 class="not" v-if="!load && getFolder == null">
          Nenhuma Pasta encontrada
        </h1>
        <modalUpload
          :id="`externalUpload`"
          :propsModal="objectModal"
          :dataField="dataField"
          @closeModal="closeModal"
          @submitform="submitformModal"
        />
        <modalFolder
          :propsModal="objectModal"
          :dataField="dataField"
          @closeModal="closeModal"
          @submitform="submitformModal"
        />
        <ModalDelete :item="item" @deleteFolder="deleteFolder" :nameModalDelete="'modalFolderDelete'" />
        <div class="mainComponent">
          <transition name="fade" mode="out-in">
            <component
              :is="cp"
              :nameModalDelete="'modalFolderDelete'"
              :items="getFolder"
              @showModal="showModal"
              @deleteFolder="openDeleteFolder"
              v-if="$route.name == 'arquivos'"
            />
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import { HalfCircleSpinner } from "epic-spinners";

export default {
  components: {
    HalfCircleSpinner,
    Card: () => import("@/components/arquivos/cardfolder"),
    ModalDelete: () =>
      import("@/components/arquivos/modalFolder/modalDelete.vue"),
    modalFolder: () => import("@/components/arquivos/modalFolder"),
    modalUpload: () => import("@/components/arquivos/files/modalUpload")
  },
  data() {
    return {
      load: true,
      sizeWindow: 0,
      cp: "Card",
      objectModal: {},
      dataField: {
        name: "",
        profiles: [],
        id: null
      },
      item: {
        nameFolder: "",
        id: ""
      },
      getFolder: [],
      profilesCategory: []
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getUser.profile;
    }
  },
  created() {
    this.getAll();
  },
  methods: {
    showModal(params, item) {
      if (params === "add") {
        this.dataField.name = "";
        this.dataField.profiles = [
          { id: 2, show: false },
          { id: 3, show: false }
        ];
        this.dataField.id = null;
        this.objectModal = {
          title: "Criar nova Pasta",
          button: "Criar"
        };
      } else {
        this.field(item);
        this.objectModal = {
          title: "Editar Pasta",
          button: "Salvar"
        };
      }
      this.openModal("modalFolder");
    },
    openModal(cp) {
      $(`#${cp}`).modal("show");
    },
    closeModal(cp) {
      $(`#${cp}`).modal("hide");
      this.dataField.name = "";
      this.dataField.id = "";
      this.dataField.profiles = [];
    },
    field(item) {
      this.dataField.name = item.name;
      this.dataField.profiles = [
        { id: 2, show: false },
        { id: 3, show: false }
      ];
      this.dataField.profiles.forEach(t => {
        if (item.trainingCategoryPermissions.some(tp => tp.profile == t.id)) {
          t.show = true;
        }
      });
      this.dataField.id = item.id;
    },
    getAll() {
      HTTP.get(`services/app/TrainingCategory/GetAll`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          const { data } = res;
          this.load = false;
          this.getFolder = data.result.trainingCategories;
        })
        .catch(err => {
          this.toast(err, this.$toast.error);
          console.log(err);
        });
    },
    async createCategory(dataField) {
      const { id, name } = dataField;
      const profiles = dataField.profiles.filter(t => t.show).map(t => t.id);
      if (!name) {
        this.toast("Nome da Pasta é obrigatório!", this.$toast.error);
        return;
      }

      if (profiles.length < 1) {
        this.toast("Selecione qual usuario tem acesso.", this.$toast.error);
        return;
      }

      const data = {
        id: id,
        name: name,
        profiles: profiles,
        trainingCategoryIds: null
      };

      try {
        await HTTP.post(`services/app/TrainingCategory/CreateOrEdit`, data, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        });
        this.getAll();
        this.closeModal("modalFolder");

        if (id) {
          this.toast("Pasta editada com Sucesso!", this.$toast);
          return;
        }

        this.toast("Pasta criada com Sucesso!", this.$toast.success);
      } catch (error) {
        this.toast(error, this.$toast.error);
        console.log(error);
      }
    },
    submitformModal() {
      this.createCategory(this.dataField);
    },
    openDeleteFolder(item) {
      this.item.nameFolder = item.name;
      this.item.id = item.id;

      $("#modalFolderDelete").modal("show");
    },
    deleteFolder(id) {
      HTTP.delete(`services/app/TrainingCategory/Delete?categoryId=${id}`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          $("#modalFolderDelete").modal("hide");
          this.getAll();
          this.toast("Pasta deletada com Sucesso!", this.$toast.error);
          return;
        })
        .catch(err => {
          this.toast(error, this.$toast.error);
          console.log(err);
        });
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
  watch: {
    $route: function(to) {
      if (to.fullPath === "/arquivos") {
        this.$store.commit("setRouterFolderFile", "");
        this.$store.commit("setRouterSubFolder", "");
      }
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

h1,
strong,
button {
  font-family: Poppins;
}

.container .header {
  border-bottom: 1px solid #3f88c1;
  padding-bottom: 10px;
}

.container .header h1,
.routerHome {
  color: #98c4e6;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.container .header strong {
  color: #fff;
}

.container .main {
  margin: 25px 0;
}

.container h1 {
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}

.main .containerMain {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main .mainComponent {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}

.btn_default {
  padding: 0 20px;
  background-color: #f38235;
  height: 40px;
  width: auto;
  border: none;
  border-radius: 6px;
  align-items: center;
  outline: none;
}

.btn_default:hover {
  background-color: #e0a638;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.not {
  font-size: 21px;
  text-align: center;
  margin: 40px;
  color: #ffffff8c !important;
}
#btn_upload {
  margin-left: 300px;
}
svg {
  margin-right: 6px;
}
@media screen and (max-width:767px) {
  .container{
    padding-top: 0!important;
    padding-left: 0;
    padding-right: 0;
  }
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
  }
  .imgWelCont{
    width: 23%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 40%;
  }
  .main{
    padding-left: 15px;
    padding-right: 15px;
  }
  .container .main{
    margin: 0;
  }
  #btn_upload {
    margin-left: 0px;
  }
  .btn_default{
    margin-right: 5px;
  }
.main .containerMain[data-v-893d4300] {
   margin-top: 5%;
}
  
}
</style>
